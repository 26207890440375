import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext';
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

// Components
import Layout from '../components/layout';
import Seo from '../components/global/seo';
import ModuleZone from '~moduleZone';

const PageTemplate = ({ data: { page } }) => {    

  const { 
    pageModules, 
    myPorterCta, 
    pageTheme, 
    headerTheme, 
    footerForms, 
    footerTheme, 
    seo 
  } = page || {}  

  const { 
    setPageBackground, 
    setFooterHidden, 
    setMyPorter, 
    setHeaderDark, 
    setFooterForms, 
    setFooterTheme, 
    setShowingBriefUs 
  } = useSiteState();

  useEffect(() => {
    if (pageTheme?.color.value) {
      document.documentElement.setAttribute('data-theme', `theme-${pageTheme.color.title}`);
      document.documentElement.style.setProperty(`--doc-bg`, pageTheme.color.value);      
      setPageBackground(pageTheme.color.value);
    }       
    setFooterForms(footerForms === true ? true : false)
    setFooterTheme(footerTheme?.color.title ? footerTheme.color.title : 'theme-none')          
    setHeaderDark(headerTheme?.color.title === "Black" ? true : false)
    setMyPorter(myPorterCta)
    setFooterHidden(false)
    setShowingBriefUs(false)
  }, [
      pageTheme, 
      setFooterHidden, 
      setPageBackground, 
      setMyPorter, 
      myPorterCta, 
      headerTheme, 
      setHeaderDark, 
      footerTheme, 
      setFooterTheme, 
      footerForms, 
      setFooterForms, 
      setShowingBriefUs
    ]
  );

  return (
    <Layout>      
      {seo && <Seo isHome={true} {...seo} />}
       {pageModules && <ModuleZone {...pageModules} />}       
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query pageQuery {
    page: sanityPageHome{     
      _id
      seo {
        ...SEO
      }
      myPorterCta
      footerForms
      headerTheme {
        color {
          value
          title
        }
      }
      footerTheme {
        color {
          value
          title
        }
      }
      pageTheme {
        color {
          value
          title
        }
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`;